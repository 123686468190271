import styled from "@emotion/styled";
import useSnackBar from "custom-hooks/useSnackBar.hook";
import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { IMessageAgent, postMessageAgent } from "api/messages.request";
import { useTranslations } from "next-intl";
import useDataLayerEvent from "custom-hooks/data-layer-event.hook";

import ContactForm from "./ContactForm";
import { ModalMessage } from "../message-modal";
import leadsCounterAdapter from "@endpoints/clients/lead counter/lead.counter.adapter";
import useLeadCountMutation from "custom-hooks/useLeadCountMutation";
import { C_LEAD_TYPE } from "@components-pages/propiedad/lead_type.enum";
import { useRouter } from "next/router";

const postData = async (data: IMessageAgent) => {
  const response = await postMessageAgent(data);
  return response;
};

function ContactAgentDialog(props: IContactAgentDialog) {
  const { ADD_LEAD_COUNT_M } = useLeadCountMutation();
  const router = useRouter();
  const { event } = useDataLayerEvent();
  const { open, onClose, defaultMessage } = props;
  const { showMessage } = useSnackBar();
  const translation = useTranslations("alerts.contactAgent");
  const translationAlert = useTranslations("alerts.contact");

  const [openSuccessDialog, setSuccessDialog] = useState(false);
  const { mutateAsync, isLoading } = useMutation(postData, {
    onSuccess: () => {
      handleModalDialog();
      setTimeout(() => {
        handleModalDialog();
        onClose();
      }, 500);
    },
    onError: () =>
      showMessage(translation("titleError"), {
        variant: "error",
      }),
  });

  const handleModalDialog = () => {
    setSuccessDialog(!openSuccessDialog);
  };

  const onSubmit = (values: IFormValues) => {
    props.onCallBackSubmit && props.onCallBackSubmit();
    const data: IMessageAgent = {
      realstate_id: props.realestateId,
      agent_id: props.entity_info.id,
      permalink: props.realestateId ? window.location.href : "",
      client_name: values.full_name,
      client_email: values.email,
      client_phone: values.phone,
      client_message: values.message,
      "g-recaptcha-response": values.captcha,
      subscribe: values.subscribe,
    };

    if (props.isAgency) {
      delete data.agent_id;
      data.agency_id = props.entity_info.id;
    }
    if (props.extraDataLayer) {
      // console.log("props.extraDataLayer", props.extraDataLayer);
      // event({
      //   event_name: "enviar_mensaje_propiedad",
      //   body: props.extraDataLayer,
      // });

      event({
        event_name: router.query?.areferer
          ? "correo_agencia_propiedad"
          : "correo_agente_propiedad",
        body: props.extraDataLayer,
      });
    }
    mutateAsync(data);
  };

  return (
    <>
      <StyledDialog open={open}>
        <ContactForm
          onSubmit={onSubmit}
          onClose={onClose}
          classNameModalButton={props.classNameModalButton}
          isLoading={isLoading}
          defaultMessage={defaultMessage}
          className={props.customClass}
        />
      </StyledDialog>
      <StyledModalMessage
        title={translationAlert("titleSuccess")}
        body={translationAlert("bodySuccess")}
        open={openSuccessDialog}
        handleClose={handleModalDialog}
      />
    </>
  );
}

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: var(#efeff1);
    border-radius: 20px;
  }
`;

const StyledModalMessage = styled(ModalMessage)`
  & .MuiBox-root {
    width: 90%;
    border-radius: 20px;
    max-width: 400px;
  }
`;

export interface IFormValues {
  full_name: string;
  phone: string;
  email: string;
  message: string;
  captcha: string;
  subscribe: boolean;
}

interface IContactAgentDialog {
  onClose: any;
  open: boolean;
  entity_info: any;
  isAgency: boolean;
  realestateId?: number;
  classNameModalButton?: string;
  defaultMessage?: string;
  extraDataLayer?: any;
  customClass?: string;
  onCallBackSubmit?: () => void;
}

export default ContactAgentDialog;
