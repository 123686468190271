
enum LeadsStatic {

  }
  
  const LeadsDynamic = {
    /**
     * @member {string}
     * @method POST
     * @version 2.0
     * @description post leads dynamic counter.
     */
    
    add: (realestate_id: number ) => `/v2/clients/realestates/${realestate_id}/lead-count`,

  };
  
  export const LeadsCountEndpoints = {
    ...LeadsDynamic,
    ...LeadsStatic,
  };
  
  export type LeadsCountEndpoints = typeof LeadsCountEndpoints;
  