
import { C_LEAD_TYPE } from "shared/models/enums/lead_type.enum";
import { LeadsCountEndpoints } from "@endpoints/clients/lead counter/lead.counts.endpoints";
import {  postRequest } from "api/base-services.request";

export interface I_LeadsCounterValues{
    params: {
        realestate_id: number
    };
    body: {
        type: C_LEAD_TYPE.WHATSAPP | C_LEAD_TYPE.MAIL | C_LEAD_TYPE.CALL | C_LEAD_TYPE.MORTAGE_REFERRAL;
        people_id?: number;
        is_referred: number;
        agency_id?: number;
    }
}

export function addLeadCount(values: I_LeadsCounterValues ) {
  return postRequest(LeadsCountEndpoints.add(values.params.realestate_id), values.body, true).then(({ data }) => data?.data);
}
