import { useMutation } from "@tanstack/react-query";
import { addLeadCount } from "api/clients/leads counter/leads.counter.request";

const useLeadCountMutation = () => {
  const { mutate: ADD_LEAD_COUNT_M } = useMutation({
    mutationFn: addLeadCount,
  });
  return { ADD_LEAD_COUNT_M };
};
export default useLeadCountMutation;
